html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  gap: 1rem;
}

ul > li {
  cursor: pointer;
}

ul > li:hover {
  text-decoration: underline;
}

.container {
  color: white;
  margin: 0;
  padding: 0;
  font-family: copse;
}

.background--image {
  background-image: url(./images/woodpanel.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.content {
  position: absolute;
  max-width: 100vw;
  padding: 3rem;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header--links {
  margin-right: 1rem;
}

.intro {
  margin: 10% 0 4rem 0;
}

.intro > h1 {
  font-size: 2rem;
}

.intro > h3 {
  font-size: 1.5rem;
}

.divider {								/* minor cosmetics */
  display: table; 
  font-size: 1.75rem; 
  text-align: center; 
  width: 90%; 						/* divider width */
  margin: 10rem auto 4rem auto;					/* spacing above/below */
}
.divider span { display: table-cell; position: relative; }
.divider span:first-child, .divider span:last-child {
  width: 50%;
  top: 1rem;							/* adjust vertical align */
  -moz-background-size: 100% 2px; 	/* line width */
  background-size: 100% 2px; 			/* line width */
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}
.divider span:first-child {				/* color changes in here */
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(rgb(255, 255, 255)));
  background-image: -webkit-linear-gradient(180deg, transparent, rgb(255, 255, 255));
  background-image: -moz-linear-gradient(180deg, transparent, rgb(255, 255, 255));
  background-image: -o-linear-gradient(180deg, transparent, rgb(255, 255, 255));
  background-image: linear-gradient(90deg, transparent, rgb(255, 255, 255));
}
.divider span:nth-child(2) {
  color: rgb(255, 255, 255); padding: 0px 5px; width: auto; white-space: nowrap;
}
.divider span:last-child {				/* color changes in here */
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgb(255, 255, 255)), to(transparent));
  background-image: -webkit-linear-gradient(180deg, rgb(255, 255, 255), transparent);
  background-image: -moz-linear-gradient(180deg, rgb(255, 255, 255), transparent);
  background-image: -o-linear-gradient(180deg, rgb(255, 255, 255), transparent);
  background-image: linear-gradient(90deg, rgb(255, 255, 255), transparent);
}

.project--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0;
  gap: 4rem;
}

.project--container > h2 {
  font-size: 1.5rem;
}

.project {
  display: flex;
}

.project--items {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.project--items > a {
  width: 45%;
}

.project--items > a > img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  cursor: pointer;
}

.project--items > div {
  width: 45%;
  font-size: 1.25rem;
}

.about--me {
  justify-content: flex-start;
}

.about--me > div {
  margin: 3rem 0;
}

.resume--container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.image--container {
  width: 60%;
}

.resume--container > a > img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.contact--blurb {
  width: 80%;
}

.contact--list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact--item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contact--icon, .contact--icon:hover {
  cursor: default;
}

/* Scroll to Top Button */

.top-to-btm{
  position: relative;
}
.icon-position{
  position: fixed;
  bottom: 2rem;
  right: 1.5rem;
  z-index: 20;
}
.icon-style{
  background-color: transparent;
  border: 1px solid white;
  border-radius: .5rem;
  height: 2rem;
  width: 2rem;
  color: #fff;
  cursor: pointer;
  transition: transform .2s;
}

.icon-style:hover {
  transform: scale(1.25);
}

@media (max-width: 795px) {
  * {
    font-size: 14px;
  }
  .content {
    padding: .5rem 2rem 2rem 2rem;
  }
  .divider span:first-child, .divider span:last-child {
    top: .75rem;
  }
  .divider--title {
    font-size: 1.25rem;
  }
  .project--items {
    flex-direction: column;
  }
  .project--items > a, .project--items > div {
    width: 100%;
  }
  #odd--element {
    order: -1;
  }
  .image--container {
    width: 90%;
  }
  .contact--blurb {
    width: 100%;
  }
}
